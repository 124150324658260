<template>
  <div class="row justify-content-center text-center mt-4 pt-2" v-if="!hide">
    <div class="col-12 col-md-8 text-center">
      <Button class="w-100" color="red" btnText="Complete Payment" icon="arrow" @buttonClicked="makePayment" :isLoading="isLoadingValue">
        <IconArrowForward class="inline-icon" />
      </Button>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default ({
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue'))
  },
  name: 'StripePaymentButton',
  props: ['isLoading', 'hide'],
  emits: ['update:isLoading', 'makePayment'],
  computed: {
    isLoadingValue: {
      get () {
        return this.isLoading
      },
      set (value) {
        this.$emit('update:isLoading', value)
      }
    }
  },
  methods: {
    async makePayment () {
      this.$emit('makePayment')
    }
  }
})
</script>
